import { ErrorMessages, LoadingInterstitial } from "@tigris/mesokit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ApiContextProvider } from "../contexts/ApiContextProvider";
import {
  OnboardingContextProvider,
  OnboardingContextProviderProps,
} from "../contexts/OnboardingContextProvider";
import { SardineRiskSession } from "../contexts/SardineRiskSession";
import { Layout } from "../Layout";
import {
  CountryCodeAlpha2,
  Network,
  OnboardingAppEvents,
  OnboardingAppRenderContext,
  OnboardingConfiguration,
  ReturnToTransferReason,
  Routes,
} from "../types";
import { ErrorCard } from "./ErrorCard";
import { err, ok, Result } from "neverthrow";
import { Sentry } from "@tigris/common";
import { useRouter } from "../hooks/useRouter";
import { toast } from "sonner";
import { DevControls } from "../dev/DevControls";
import { InstrumentKind, ProfileFieldStatus } from "../generated/sdk";
import { getNextOnboardingStep } from "../utils/nextOnboardingStep";

const validMesoTransferOrigins = [
  "http://localhost:4001",
  "http://localhost:5173",
  "https://api.meso.network",
  "https://api.sandbox.meso.network",
  "https://api.preview.meso.plumbing",
  "https://api.dev.meso.plumbing",
];

const configurationTimeoutDuration = 2_000;

const getOnboardingAppEventForReasonCode = (
  reason: ReturnToTransferReason,
): Result<OnboardingAppEvents, undefined> => {
  switch (reason) {
    case "onboardingComplete":
      return ok(OnboardingAppEvents.ONBOARDING_COMPLETE);
    case "returnToLogin":
      return ok(OnboardingAppEvents.ONBOARDING_RETURN_TO_LOGIN);
    case "onboardingTerminated":
      return ok(OnboardingAppEvents.ONBOARDING_TERMINATED);
    default:
      return err(undefined);
  }
};

// This is the onboarding app that runs when popped out from an iframe
/** A wrapper component to negotiate communication with a transfer frame (embedded, inline) while running onboarding in a first-party window. */
export const OnboardingAppForTransfer = () => {
  const [configuration, setConfiguration] = useState<
    OnboardingConfiguration | undefined
  >(() => {
    if (
      import.meta.env.VITE_TIGRIS_ENV === "local" &&
      !window.opener &&
      new URLSearchParams(window.location.search).get("devMode") === "true"
    ) {
      return {
        network: Network.SOLANA_MAINNET,
        walletAddress: "AGzbmTr8nyQs5cH9LXaMekoBDhuWjE9yNsDsBtWL2dcm",
        partner: {
          id: "meso-dev",
          displayName: "Meso Dev",
          logoUri: "https://assets.meso.network/spam.png",
        },
        session: {
          id: "s_001",
          token: "token",
          isReturningUser: false,
          passkeysEnabled: false,
          applepayEnabled: false,
          // If our dev tools have overwritten the feature flag for local development, use it
          euEnabled:
            localStorage.getItem("mesoTestHarnessFlags:euEnabled") === "true",

          riskSession: {
            userId: "001",
            sessionKey: "sk1",
            clientId: "c1",
            environment: "sandbox",
          },

          // Simulate a device recognized as in the US
          deviceCountry: CountryCodeAlpha2.US,
        },
        supportedPaymentMethods: [InstrumentKind.PAYMENT_CARD],
        profileStatus: {
          __typename: "ProfileStatus",
          acceptedMesoTerms: false,
          acceptedZeroHashTerms: false,
          account: false,
          financialInstrument: false,
          basicInfo: false,
          phone: false,
          phoneVerified: false,
          residentialAddress: false,
          taxpayerId: false,
          citizenship: false,
          acknowledgedScamWarning: false,
          residenceCountry: null,
          pesel: ProfileFieldStatus.UNKNOWN,
          identityDocuments: false,
        },
      };
    }

    return undefined;
  });
  const [hasInitializationError, setHasInitializationError] = useState(false);
  const [opener, setOpener] = useState<string>();
  const { navigate, disableNavigation } = useRouter();

  // Initialize transfer window context
  useEffect(() => {
    if (configuration) return;

    // Check if the user has refreshed the page. If they have, they should be shown an error so they can return to the transfer window and start over
    // https://stackoverflow.com/a/53307588
    // In a future rev, we can be more intelligent about this and track the user's onboarding state by shuttling messages back to the transfer window such that when
    // the page is reloaded, we can drop the user back into onboarding where they were before. Doing it this way for now also helps us guard against stale sessions.
    try {
      const pageAccessedByReload =
        window.performance &&
        window.performance
          .getEntriesByType("navigation")
          .map((nav: PerformanceEntry) => ("type" in nav ? nav.type : ""))
          .includes("reload");

      if (pageAccessedByReload) {
        setHasInitializationError(true);
        return;
      }
    } catch (e) {
      Sentry.captureException(e, {
        extra: { operation: "pageAccessedByReload" },
      });
      setHasInitializationError(true);
      return;
    }

    const configurationTimeout: ReturnType<typeof setTimeout> = setTimeout(
      () => setHasInitializationError(true),
      configurationTimeoutDuration,
    );

    const postMessageConfigurationHandler = (event: MessageEvent) => {
      if (!validMesoTransferOrigins.includes(event.origin)) return;
      clearTimeout(configurationTimeout);
      setOpener(event.origin);

      if (
        event?.data?.kind ===
        OnboardingAppEvents.RETURN_ONBOARDING_CONFIGURATION
      ) {
        // Detach handler
        window.removeEventListener("message", postMessageConfigurationHandler);

        setConfiguration({
          network: event.data.payload.network,
          walletAddress: event.data.payload.walletAddress,
          partner: event.data.payload.partner,
          session: event.data.payload.session,
          supportedPaymentMethods: event.data.payload.supportedPaymentMethods,
          profileStatus: event.data.payload.profileStatus,
        });

        const nextStep = getNextOnboardingStep({
          profileStatus: event.data.payload.profileStatus,
          supportedPaymentMethods: event.data.payload.supportedPaymentMethods,
          user: event.data.payload.user,
        });

        if (nextStep.isOk()) {
          navigate(nextStep.value);
        }
      }
    };

    window.addEventListener("message", postMessageConfigurationHandler);

    window.opener.postMessage(
      { kind: OnboardingAppEvents.GET_ONBOARDING_CONFIGURATION },
      "*",
    );

    return () => clearTimeout(configurationTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReturnToTransfer = useCallback<
    OnboardingContextProviderProps["onReturnToTransfer"]
  >(
    (reason) => {
      // Check that we can still communicate with the parent window.
      if (!("postMessage" in (window.opener || {})) || !opener) {
        Sentry.captureException(
          "handleReturnToTransfer: Unable to find `window.opener`",
          { extra: { reason } },
        );

        disableNavigation();

        switch (reason) {
          case "onboardingComplete":
          case "returnToLogin":
            navigate(Routes.HandoffFailed, { reason });
            break;
          case "onboardingTerminated":
            toast.error(ErrorMessages.onboardingWindow.GENERIC_ERROR);
            break;
          default:
            break;
        }

        return;
      }

      const eventKindResult = getOnboardingAppEventForReasonCode(reason);

      if (eventKindResult.isErr()) {
        Sentry.captureException(
          "handleReturnToTransfer: Unable to determine event kind",
          { extra: { reason } },
        );
        return;
      }

      window.opener.postMessage({ kind: eventKindResult.value }, opener);
    },
    [disableNavigation, navigate, opener],
  );

  const isReady = useMemo(() => {
    return (
      configuration && Object.values(configuration!).every((value) => !!value)
    );
  }, [configuration]);

  if (hasInitializationError) {
    return <ErrorCard />;
  }

  if (!isReady) {
    return <LoadingInterstitial mode="loading" />;
  }

  return (
    <ApiContextProvider providedSession={configuration!.session}>
      <OnboardingContextProvider
        network={configuration!.network}
        walletAddress={configuration!.walletAddress}
        partner={configuration!.partner}
        onReturnToTransfer={handleReturnToTransfer}
        renderContext={OnboardingAppRenderContext.BREAKOUT_FROM_TRANSFER}
        supportedPaymentMethods={configuration!.supportedPaymentMethods}
        profileStatus={configuration!.profileStatus}
      >
        <SardineRiskSession
          flow="onboarding"
          onError={() => setHasInitializationError(true)}
        >
          <Layout useToast />
        </SardineRiskSession>
        <DevControls />
      </OnboardingContextProvider>
    </ApiContextProvider>
  );
};
